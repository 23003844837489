import Raven from 'raven-js';
import * as fedopsLogger from '@wix/fedops-logger';
import { EditorSDK, Origin } from '@wix/platform-editor-sdk';

const RAVEN_INIT_STRING = 'https://716c4e6c61414362b1685d59d2dc4814@sentry.wixpress.com/294';
const FEDOPS_APP_NAME = 'profile-card-tpa-editor-script';

let fedopsInstance: fedopsLogger.FedopsLogger;

interface Options {
  firstInstall: boolean;
  origin: Origin;
}

export async function initializeMonitoring(editorSDK: EditorSDK, { firstInstall, origin }: Options) {
  const [metaSiteId, siteRegion, siteLanguage] = await Promise.all([
    editorSDK.info.getMetaSiteId(''),
    editorSDK.info.getSiteRegion(''),
    editorSDK.info.getLanguage('')
  ]);

  const tags = {
    editorType: origin && origin.type,
    metaSiteId,
    siteRegion,
    siteLanguage,
    firstInstall: firstInstall.toString()
  };

  Raven.config(RAVEN_INIT_STRING, { tags }).install();
  fedopsInstance = fedopsLogger.create(FEDOPS_APP_NAME);
}

export function interactionStarted(interactionName: string) {
  try {
    fedopsInstance.interactionStarted(interactionName);
  } catch (e) {
    const err = `Failed to start fedops interaction, reason: ${e}`;
    Raven.captureException(err);
  }
}

export function interactionEnded(interactionName: string) {
  try {
    fedopsInstance.interactionEnded(interactionName);
  } catch (e) {
    const err = `Failed to end fedops interaction, reason: ${e}`;
    Raven.captureException(err);
  }
}

export function interactionFailed(interactionName: string, err: string | Error) {
  Raven.captureException(err, { tags: { interactionName } });
}

export async function toMonitored<T extends Function>(interactionName: string, promise: T) {
  try {
    interactionStarted(interactionName);
    const response = await promise();
    interactionEnded(interactionName);
    return response;
  } catch (err) {
    interactionFailed(interactionName, err);
    throw err;
  }
}
