import { EditorPlatformApp } from '@wix/platform-editor-sdk';
import { isHorizontalLayoutEnabled } from './services/experiments';
import { setNewLayoutStyleForProfileWidget } from './services/styles';
import { toMonitored, initializeMonitoring } from './services/monitoring';

export const editorScript: EditorPlatformApp = {
  editorReady: async (editorSDK, _, options) => {
    await initializeMonitoring(editorSDK, options);

    if (!options.firstInstall) {
      return;
    }

    const isHorizontalLayout = await isHorizontalLayoutEnabled();

    if (isHorizontalLayout) {
      await toMonitored('setHorizontalLayout', () => {
        return setNewLayoutStyleForProfileWidget(editorSDK);
      });
    }
  }
};
